@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-width: 10; // put to "none" for no scrollbar
}

@font-face {
  font-family: SourceSansProReg;
  src: url("assets/fonts/source-sans-pro.regular.ttf");
}

@font-face {
  font-family: SourceSansProBold;
  src: url("assets/fonts/source-sans-pro.bold.ttf");
}

@font-face {
  font-family: SourceSansProSemiBold;
  src: url("assets/fonts/source-sans-pro.semibold.ttf");
}

@font-face {
  font-family: SourceSansProItalic;
  src: url("assets/fonts/source-sans-pro.italic.ttf");
}

.nav-dot {
  position: relative;
}

.nav-dot::after {
  content: "";
  position: absolute;
  bottom: -8px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: #361692; /* Customize the color as needed */
  border-radius: 50%;
}

.nav-dotmobile {
  position: relative;
}

.nav-dotmobile::after {
  content: "";
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: -36px; /* Adjust as needed */
  transform: translateY(-50%);
  width: 11px;
  height: 11px;
  background-color: #ffffff; /* Customize the color as needed */
  border-radius: 50%;
}

img {
  display: block;
  max-width: 100%;
}

.hexagon {
  transform: rotate(90deg);
  width: 340px;
  height: 306px;
  clip-path: path(
    "M114,6 L246,6 A36,36 0 0,1 282,30 L336,132 A36,36 0 0,1 336,168 L282,270 A36,36 0 0,1 246,294 L114,294 A36,36 0 0,1 78,270 L24,168 A36,36 0 0,1 24,132 L78,30 A36,36 0 0,1 114,6"
  );
}
