nav {
    width: 100%;
    position: absolute;
    z-index: 200;

ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        margin-top: 2rem;
        padding-bottom: 20px;

        li {
        margin: 0;
        padding: 0;
        align-self: center;
        flex-grow: 1;

        &:first-child {
            flex-grow: 14;
        }

        &:last-of-type {
            margin-right: 0%;
        }

            a {
                text-decoration: none;
                display: inline-block;
                color: white;
            }
        }
    }
}
  
@media (max-width: 640px) {
    nav {
        position: absolute;
        & > ul {
        display: none;
        }
    }
}